











































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'

@Component({
  directives: { mask }
})
export default class QuestionItemEditable extends Vue {
  @Prop({ default: true }) correct!: boolean
  @Prop() question!: Question
  questionModel: Question = {} as Question

  isVisible = false

  time = {
    minutes: 0,
    seconds: 0
  }

  @Watch('time', { deep: true })
  onTimeChange () {
    this.questionModel.time = this.time.minutes * 60 + this.time.seconds
  }

  get questionTime () {
    return {
      minutes: Math.floor(this.question.time / 60),
      seconds: this.question.time % 60
    }
  }

  created () {
    this.questionModel = { ...this.question }
    this.time.minutes = this.questionTime.minutes
    this.time.seconds = this.questionTime.seconds
  }

  @Watch('questionModel', { deep: true })
  updateQuestion () {
    this.$emit('input', this.questionModel)
  }

  get questionTileClasses () {
    return {
      'p-0': true,
      'w-full': true
    }
  }
}
