import { render, staticRenderFns } from "./EditableQuestionOption.vue?vue&type=template&id=d905a3a8&scoped=true&"
import script from "./EditableQuestionOption.vue?vue&type=script&lang=ts&"
export * from "./EditableQuestionOption.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d905a3a8",
  null
  
)

export default component.exports