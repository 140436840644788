

















































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Close16 from '@carbon/icons-vue/es/close/16'

import EditableOption from '@/partials/components/Exam/EditableQuestionOption.vue'
import EditableBody from '@/partials/components/Exam/EditableQuestionBody.vue'

@Component({
  components: {
    Edit16,
    Close16,
    EditableOption,
    EditableBody
  }
})
export default class ExamPreview extends Vue {
  @Prop({ required: true }) selectedQuestions!: Array<Question>
  @Prop({ required: true }) navigation!: boolean
  currentQuestionIndex = 0

  canEdit = false
  canSeeCorrectAnswer = false
  canSeeJustification = false

  form = {
    option: ''
  }

  toggleCorrectAnswer () {
    this.canEdit = !this.canEdit
    this.canSeeCorrectAnswer = !this.canSeeCorrectAnswer
  }

  toggleJustification () {
    this.canSeeJustification = !this.canSeeJustification
  }

  isEditing: Array<number> = []
  toggleEditOption (option: any, index: number) {
    if (this.isEditing.includes(index)) {
      const indexToRemove = this.isEditing.indexOf(index)

      if (indexToRemove !== -1) {
        this.isEditing.splice(indexToRemove, 1)
      }
    } else {
      this.isEditing.push(index)
    }
  }

  isEditingBody = false
  toggleEditBody () {
    this.isEditingBody = !this.isEditingBody
  }

  updateBody (newBody: string) {
    this.selectedQuestions[this.currentQuestionIndex].body = newBody
  }

  updateOption (updatedOption: any, index: number) {
    if (updatedOption.correct) {
      this.selectedQuestions[this.currentQuestionIndex].options = this.selectedQuestions[this.currentQuestionIndex].options.map(option => {
        option.correct = false
        return option
      })
    }
    this.selectedQuestions[this.currentQuestionIndex].options[index] = updatedOption
  }

  next () {
    this.currentQuestionIndex++
    this.resetOptions()
  }

  prev () {
    this.currentQuestionIndex--
    this.resetOptions()
  }

  resetOptions () {
    this.canEdit = false
    this.canSeeCorrectAnswer = false
    this.canSeeJustification = false
    this.isEditingBody = false
    this.isEditing = []
  }

  done () {
    this.currentQuestionIndex = 0
    this.resetOptions()
    this.$emit('done')
  }
}
