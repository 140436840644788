





























import { Component, Vue, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class EditableQuestionBody extends Vue {
  @Prop({ required: true }) body!: string
  @Prop({ required: true }) questionId!: string

  done = true

  form = {
    body: ''
  }

  created () {
    this.validations()
    this.form.body = this.body
  }

  submit () {
    this.done = false
    axios.patch(`question/${this.questionId}/body`, this.form)
      .then(() => {
        this.$emit('success', this.form.body)
      })
      .finally(() => { this.done = true })
  }

  validations () {
    extend('required', {
      ...required,
      message: 'The {_field_} field is required.'
    })
  }
}
