































import { Component, Vue, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class EditableQuestionOption extends Vue {
  @Prop({ required: true }) correct!: boolean
  @Prop({ required: true }) option!: any

  done = true

  form = {
    option: '',
    correct: false
  }

  created () {
    this.validations()
    this.form.option = this.option.option
  }

  submit () {
    this.done = false
    if (this.correct) { this.form.correct = true }
    axios.patch(`question/${this.option.questionId}/option/${this.option.id}`, this.form)
      .then(response => {
        this.$emit('success', response.data.data)
      })
      .finally(() => { this.done = true })
  }

  validations () {
    extend('required', {
      ...required,
      message: 'The {_field_} field is required.'
    })
  }
}
